import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { signup, logoutUser } from '../../../../actions'
import { _searchToJson } from '../../../../util'
import { validateOnServer } from './SignupForm/validate'
import { _generateRandomFormFields } from './util'
import './Signup.css'

class Signup extends Component {
  static propTypes = {
    location: PropTypes.shape({}).isRequired,
    signup: PropTypes.func.isRequired,
    logoutUser: PropTypes.func.isRequired,
  }

  state = {
    redirectQuery: 'prospect',
    confirmAccountView: false,
    confirmUsername: '',
    devDummyFormValues: {},
  }

  componentWillMount() {
    this.props.logoutUser()
    this.handleDummyFormMode()
  }

  handleDummyFormMode = () => {
    const { location } = this.props
    const searchQueries = _searchToJson(location.search)
    if (
      searchQueries.dummy === 'true' &&
      window.IRISVR_ENV.base_url !== 'https://api.irisvr.com/v3'
    ) {
      this.setState({
        devDummyFormValues: _generateRandomFormFields(),
      })
    }
  }

  handleRedirect = username =>
    this.setState({
      confirmAccountView: true,
      confirmUsername: username,
    })

  signupUser = data => {
    const { location, signup } = this.props
    const userData = this.formatUserData(data)
    if (location.search) {
      const searchQueries = _searchToJson(location.search)
      const { redirect, action, return_to: returnTo } = searchQueries
      if (redirect) userData.redirect = redirect

      // Handle rare case where user is redirected from Zendesk
      // but does not yet have an account with Iris. This will
      // ensure that the user's account confirmation link will
      // contain a Zendesk redirect URL upon successful auth.
      if (action === 'iris-zendesk-sso') {
        userData.redirect = returnTo
        userData.flavor = 'iris-zendesk-sso'
      }
    }
    return signup(userData)
      .then(validateOnServer)
      .then(() => this.handleRedirect(data.username))
  }

  formatUserData = data => {
    const userData = {
      personal_info: {},
      email_subscription: {
        general: {
          subscribed: data.emailSubscription || false,
        },
      },
    }
    userData.full_name = data.fullName
    userData.display_name = data.preferredName
    userData.username = data.username
    userData.password = data.password
    userData.personal_info.industry = data.industry
    userData.personal_info.company = data.company
    userData.personal_info.job_title = data.title
    userData.personal_info.country = data.country
    userData.personal_info.state = data.state
    userData.personal_info.city = data.city
    userData.personal_info.phone = data.phoneNumber
    return userData
  }

  render() {
    const {
      confirmAccountView,
      confirmUsername,
      devDummyFormValues,
    } = this.state

    return (
      <div className="signup-page">

      </div>
    )
  }
}

const mapStateToProps = state => ({
  token: state.auth.token,
})

export default connect(mapStateToProps, {
  signup,
  logoutUser,
})(Signup)
